import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-back-navigation-button',
  templateUrl: './back-navigation-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BackNavigationButtonComponent {

  @Input() label: string;
  @Input() defaultUrl: string;
  @Input() forceDefault = false;

}
