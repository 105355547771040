<div class="flex flex-col gap-2">
  <object
    *showIfSafari="false"
    class="h-[70vh]"
    [data]="source"
    type="application/pdf"
  >
    Prévisualisation du PDF
  </object>

  <object
    *showIfSafari="true"
    class="h-[70vh]"
    [data]="source"
  >
    Prévisualisation du PDF
  </object>
</div>
