<div class="flex flex-col items-center justify-center gap-3">
  <p data-testid="saturated-storage-text">
    Votre espace de stockage est saturé. Contactez votre support pour ajouter de l'espace.
  </p>
  <a
    type="button"
    tuiButton
    data-testid="saturated-storage-button"
    href="mailto:contact@pixacare.com?subject=Limite de stockage atteinte&body=Bonjour,
  J%27aimerais supprimer la limite de stockage imposée sur l%27application, pouvez-vous m%27aider ?
  Cordialement,"
  >
    Gérer votre espace de stockage
  </a>
</div>
