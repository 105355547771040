import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { FlattenedLicense, License } from '@pixacare/pxc-ts-core';
import { LICENSES_FEATURE_KEY } from './licenses.selectors';

export const licensesActions = createActionGroup({
  source: LICENSES_FEATURE_KEY,
  events: {
    'Load licenses': emptyProps(),
    'Load licenses success': props<{ flattenedLicenses: FlattenedLicense[] }>(),
    'Answer invitation': props<{ licenseId: number; accepted: boolean }>(),
    'Answer invitation success': props<{ license: License }>(),
  },
});
