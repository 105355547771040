import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';

@Component({
  selector: 'pxc-toggle',
  templateUrl: './toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent {

  @Input() toggle = false;
  @Input() hint?: string;
  @Input() disabled = false;
  @Output() toggleChange = new EventEmitter<boolean>();

}
