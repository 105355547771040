import {
  ChangeDetectionStrategy,
  Component,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MedicalSpecialty } from '@pixacare/pxc-ts-core';
import { MedicalSpecialtiesHttpService } from 'src/app/services/http/medical-specialties.http.service';

@Component({
  selector: 'pxc-medical-specialty-input',
  templateUrl: './medical-specialty-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MedicalSpecialtyInputComponent,
    },
  ],
})
export class MedicalSpecialtyInputComponent implements ControlValueAccessor {

  items$ = this.medicalSpecialtiesService.getMedicalSpecialties();

  disabled = false;
  touched = false;
  value: number = null;

  onChange: (value: number) => void = () => {};
  onTouched: () => void = () => {};

  // eslint-disable-next-line @typescript-eslint/member-ordering
  constructor(private readonly medicalSpecialtiesService: MedicalSpecialtiesHttpService) {}

  onValueChange(value: MedicalSpecialty): void {
    this.markAsTouched();
    this.value = value?.id ?? null;
    this.onChange(this.value);
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: (value: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  stringify(item: MedicalSpecialty): string {
    return item.label;
  }

  getFromId(items: MedicalSpecialty[], id: number): MedicalSpecialty {
    return items?.find((item) => item.id === id);
  }

}
