import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerStatusHttpService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  getStatus(): Observable<HttpResponse<void>> {
    return this.http.get<void>(`${environment.apiBaseUrl}/status`, { observe: 'response' });
  }

}
