import {
  Directive, TemplateRef, ViewContainerRef,
} from '@angular/core';

@Directive()
export class CreateContentDirective {

  constructor(
    protected templateRef: TemplateRef<unknown>,
    protected container: ViewContainerRef,
  ) { }

  createContent(show: boolean = true): void {
    this.container.clear();
    if (show) {
      this.container.createEmbeddedView(this.templateRef);
    }
  }

}
