import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiLoader } from '@taiga-ui/core';

@Component({
  selector: 'pxc-spinner',
  templateUrl: './spinner.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TuiLoader,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {

  @Input() isVisible = true;

}
