<div class="w-18 my-auto flex flex-col">
  <p class="flex items-center justify-center gap-1 text-current">
    <span class="text-3xl">{{ day }}</span>
    <span class="text-sm leading-3">
      {{ month }}
      <br />
      {{ year }}
    </span>
  </p>
  @if (displayTime) {
    <p
      class="text-current text-secondary"
      data-testid="time"
    >
      {{ time }}
    </p>
  }
</div>
