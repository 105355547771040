<div class="flex w-fit items-center justify-center gap-2">
  @if (hint) {
    <tui-icon
      [tuiTooltip]="hint"
      tuiHintDirection="top"
      data-testid="hint"
    ></tui-icon>
  }
  <span>
    <ng-content></ng-content>
  </span>
  <input
    tuiSwitch
    type="checkbox"
    [ngModel]="toggle"
    (ngModelChange)="toggleChange.emit($event)"
    [disabled]="disabled"
    data-testid="toggle"
    size="m"
    ngDefaultControl
  />
</div>
