<div
  class="flex w-full items-center gap-3 overflow-hidden"
  [ngClass]="{
    'flex-col': vertical,
  }"
>
  <ng-content select="[leading]"></ng-content>
  <div class="flex flex-1 flex-col">
    <div class="flex w-full justify-between gap-1">
      <p
        class="w-full"
        [ngClass]="{
          'font-medium': size === Size.MEDIUM,
          'text-left': !vertical,
          'text-center': vertical,
        }"
      >
        <ng-content select="[title]"></ng-content>
      </p>
      <p class="text-sm text-tertiary">
        <ng-content select="[date]"></ng-content>
      </p>
    </div>
    <p
      class="text-sm leading-tight text-secondary"
      [ngClass]="{
        'text-left': !vertical,
        'text-center': vertical,
      }"
    >
      <ng-content select="[subtitle]"></ng-content>
    </p>
  </div>
  <ng-content select="[trailing]"></ng-content>
</div>
