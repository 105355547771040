<button
  class="flex h-full w-full gap-6 rounded-3xl bg-base p-6 text-left ring-1 ring-border-normal transition hover:shadow-lg hover:ring-0 focus:shadow-lg focus:ring-0"
>
  <div>
    <tui-icon
      [icon]="icon"
      data-testid="card-button-icon"
      class="mb-2"
    ></tui-icon>
    <p class="text-lg font-medium">{{ title }}</p>
    <p class="text-sm text-secondary">{{ description }}</p>
  </div>
  <tui-icon
    icon="@tui.chevron-right"
    class="ml-auto flex-shrink-0 self-center text-tertiary"
  ></tui-icon>
</button>
