import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PublicUser, SearchedUser } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserHttpService {

  constructor(private readonly http: HttpClient) { }

  getUser(userId: number): Observable<PublicUser> {
    return this.http.get<PublicUser>(
      `${environment.apiBaseUrl}/User/${userId}`,
    );
  }

  getAbbreviatedUser(userId: number): Observable<string> {
    return this.http.get<string>(
      `${environment.apiBaseUrl}/User/${userId}?abbreviated=true`,
      {
        responseType: 'text' as 'json',
      },
    );
  }

  searchUsers(searchPattern: string, userIds: number[] = []): Observable<SearchedUser[]> {
    return this.http.post<SearchedUser[]>(
      `${environment.apiBaseUrl}/user/Search`, { searchPattern, userIds },
    );
  }

  refreshRtpToken(): Observable<string> {
    return this.http.get<string>(
      `${environment.apiBaseUrl}/user/rtpToken`,
      {
        responseType: 'text' as 'json',
      },
    );
  }

}
