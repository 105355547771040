<div
  class="flex select-none items-center justify-center rounded-full"
  [ngClass]="{
    'h-8 w-8': size === Size.TINY,
    'h-10 w-10': size === Size.SMALL,
    'h-12 w-12': size === Size.MEDIUM,
    'h-16 w-16': size === Size.LARGE,
  }"
  [ngStyle]="{
    'background-color': avatarColors?.backgroundColor,
  }"
  data-testid="avatar"
>
  <p
    class="font-bold"
    [ngClass]="{
      'text-xs': size === Size.TINY,
      'text-lg': size === Size.MEDIUM,
      'text-xl': size === Size.LARGE,
    }"
    [ngStyle]="{
      color: avatarColors?.textColor,
    }"
  >
    {{ text }}
  </p>
</div>
