import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { DepartmentMembership } from '@pixacare/pxc-ts-core';
import { Department } from '../../models/base/department';
import { isDateFromFuture } from '../../utils/utils';
import { DepartmentsState } from './departments.state';

export const DEPARTMENTS_FEATURE_KEY = 'Departments';

const selectDepartmentsState = createFeatureSelector<DepartmentsState>(
  DEPARTMENTS_FEATURE_KEY,
);

export const selectDepartments = createSelector(
  selectDepartmentsState,
  (state: DepartmentsState) => state.departments,
);

export const selectClientDepartment = (
  clientCode: string, departmentId: number,
): MemoizedSelector<DepartmentsState, Department> => createSelector(
  selectDepartmentsState,
  (state: DepartmentsState) => state.departments[clientCode][departmentId]);

export const selectClientDepartments = (
  clientCode: string,
): MemoizedSelector<DepartmentsState, Department[]> => createSelector(
  selectDepartmentsState,
  (state) => Object.values(state.departments[clientCode] ?? {}));

export const selectUserEnabledClientDepartments = (
  clientCode: string,
  userId: number,
): MemoizedSelector<DepartmentsState, number[]> => createSelector(selectDepartmentsState, (state) =>
  Object.values(state.departments[clientCode] || {}).reduce((acc, department) => {

    if (isDateFromFuture(new Date(department.memberships[userId].disabledOn))) {
      acc.push(department.id);
    }

    return acc;
  }, []),

);

export const selectDepartmentMemberships = (
  clientCode: string, departmentId: number,
): MemoizedSelector<DepartmentsState, { [userId: number]: DepartmentMembership }> => createSelector(
  selectDepartmentsState,
  (state: DepartmentsState) => (state.departments[clientCode][departmentId] || {}).memberships,
);

export const selectIsUserDepartmentAdmin = (
  clientCode: string,
  departmentId: number,
  userId: number,
): MemoizedSelector<DepartmentsState, boolean> => createSelector(
  selectDepartmentsState,
  (state: DepartmentsState) => (state.departments[clientCode][departmentId] || {}).memberships?.[userId]?.isAdmin,
);

export const selectDepartmentName = (
  clientCode: string,
  departmentId: number,
): MemoizedSelector<DepartmentsState, string> => createSelector(
  selectDepartmentsState,
  (state: DepartmentsState) => (state.departments[clientCode][departmentId] || {}).name,
);
