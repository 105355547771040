import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MedicalSpecialty } from '@pixacare/pxc-ts-core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MedicalSpecialtiesHttpService {

  constructor(private readonly httpClient: HttpClient) { }

  getMedicalSpecialties(): Observable<MedicalSpecialty[]> {
    return this.httpClient
      .get<MedicalSpecialty[]>(`${environment.apiBaseUrl}/MedicalSpecialty`);
  }

}
