import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-tile',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
      }
    `,
  ],
})
export class TileComponent {

  @Input() size = Size.MEDIUM;
  @Input() vertical = false;
  Size = Size;

}
