import {
  Directive, Input, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Patient, Sequence } from '@pixacare/pxc-ts-core';
import { AuthorizationsService } from 'src/app/services/authorizations.service';
import { CreateContentDirective } from './create-content.directive';

@UntilDestroy()
@Directive({
  selector: '[showIfUserAuthorizedForSequence], [showIfUserAuthorizedForPatient]',
})
export class ShowIfUserAuthorizedDirective extends CreateContentDirective {

  constructor(
    protected authorizationsService: AuthorizationsService,
    protected templateRef: TemplateRef<any>,
    protected container: ViewContainerRef,
  ) {
    super(templateRef, container);
  }

  @Input()
  set showIfUserAuthorizedForSequence(sequence: Sequence) {
    this.authorizationsService.isUserAuthorizedForSequence(sequence)
      .pipe(untilDestroyed(this))
      .subscribe((isAuthorized) => {
        this.createContent(isAuthorized);
      });
  }

  @Input()
  set showIfUserAuthorizedForPatient(patient: Patient) {

    if (this.authorizationsService.getUserId() === patient.createdBy) {
      this.createContent(true);
      return;
    }

    this.authorizationsService.isUserAuthorized$()
      .pipe(untilDestroyed(this))
      .subscribe((isAuthorized) => {
        this.createContent(isAuthorized);
      });

  }

}
