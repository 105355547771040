import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';

export const loadAppInitializationData = async (
  appConfig: AppConfigHttpService,
  authenticationService: AuthenticationService,
): Promise<void> => {
  await Promise.all([
    appConfig.getAppConfig(),
    authenticationService.getCurrentUser(),
  ]);
};

export const initializerConfigFn = (
  appConfig: AppConfigHttpService,
  authenticationService: AuthenticationService,
) => async (): Promise<void> => loadAppInitializationData(appConfig, authenticationService);
