import { HttpStatusCode } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TuiAlertService } from '@taiga-ui/core';
import { BehaviorSubject, filter, first } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';
import { ServerStatusHttpService } from 'src/app/services/http/server-status.http.service';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { lifeCycleActions } from 'src/app/shared/store/life-cycle/life-cycle.actions';
import { loadAppInitializationData } from 'src/app/shared/utils/app-initialization';

@Component({
  templateUrl: './server-unavailable.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerUnavailableComponent implements OnInit {

  Status = Status;
  isStatusChecking$ = new BehaviorSubject(false);

  constructor(
    private readonly router: Router,
    private readonly serverStatusService: ServerStatusHttpService,
    private readonly authenticationService: AuthenticationService,
    private readonly appConfigService: AppConfigHttpService,
    private readonly store: Store,
    private readonly alertService: TuiAlertService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(lifeCycleActions.resetState());
    this.retryConnection();
  }

  retryConnection(): void {
    this.isStatusChecking$.pipe(
      first(),
      filter((isStatusChecking) => !isStatusChecking),
    ).subscribe(() => {
      this.isStatusChecking$.next(true);
      this.serverStatusService.getStatus()
        .subscribe({
          next: async (pingResult) => {
            if (pingResult.status === HttpStatusCode.Ok) {
              await loadAppInitializationData(this.appConfigService, this.authenticationService);
              this.router.navigate([''], { queryParamsHandling: 'merge' });
            }
          },
          error: () => {
            this.alertService.open(
              'Impossible d\'accéder au service...', {
                label: 'Service indisponible',
                appearance: 'error',
              }).subscribe();
            this.isStatusChecking$.next(false);
          },
        });
    });
  }

}
