<div class="relative flex items-center justify-center">
  @if (max > itemsCount) {
    <button
      tuiIconButton
      appearance="flat"
      class="!absolute -left-9 top-1/2 z-10 !hidden -translate-y-1/2 lg:!block"
      [ngClass]="{
        invisible: index === 0,
        '!hidden': hideControlsOnMobile,
      }"
      (click)="previous()"
      data-testid="previous"
      size="s"
      type="button"
    >
      <tui-icon icon="@tui.chevron-left"></tui-icon>
    </button>
  }

  <ng-content></ng-content>

  @if (max > itemsCount) {
    <button
      tuiIconButton
      type="button"
      appearance="flat"
      class="!absolute -right-9 top-1/2 z-10 !hidden -translate-y-1/2 lg:!block"
      [ngClass]="{
        invisible: index + itemsCount > max - 1,
        '!hidden': hideControlsOnMobile,
      }"
      iconStart="@tui.chevron-right"
      (click)="next()"
      data-testid="next"
      size="s"
    >
      <tui-icon icon="@tui.chevron-right"></tui-icon>
    </button>
  }
</div>
