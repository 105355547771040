import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent {

  @Input() icon?: string;
  @Input() appearance?: string;
  @Input() actions: { [key: string]: MenuAction };
  @Input() selectedKey: string;

  open = false;

  onClick(action: MenuAction): void {
    action.execute();
    this.open = false;
  }

}
