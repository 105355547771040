<div
  class="group flex cursor-pointer items-center gap-2"
  backButton
  [defaultBackUrl]="defaultUrl"
  [forceDefault]="forceDefault"
>
  <tui-icon
    icon="@tui.chevron-left"
    class="text-tertiary duration-200 ease-linear group-hover:text-secondary"
  ></tui-icon>
  <p class="font-medium text-tertiary duration-200 group-hover:ml-1 group-hover:text-secondary">
    {{ label }}
  </p>
</div>
