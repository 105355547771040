<div class="flex h-screen flex-col items-center justify-center gap-6 bg-blue-50 px-4">
  <pxc-loader
    [status]="(isStatusChecking$ | async) ? Status.LOADING : Status.ERROR"
    [useLogo]="true"
  ></pxc-loader>

  <h1
    tuiTitle="l"
    class="text-center"
  >
    Service indisponible
  </h1>
  <p class="text-balance text-center">
    Veuillez nous excuser pour la gêne occasionnée.
    <br />
    Merci de réessayer dans quelques instants.
  </p>
  <button
    tuiButton
    appearance="primary"
    size="l"
    (click)="retryConnection()"
    [loading]="isStatusChecking$ | async"
  >
    Réessayer
  </button>
</div>
