import { Platform } from '@angular/cdk/platform';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[showIfSafari]',
})
export class ShowIfSafariDirective {

  constructor(
    private readonly platform: Platform,
    protected templateRef: TemplateRef<any>,
    protected container: ViewContainerRef,
  ) { }

  @Input()
  set showIfSafari(display: boolean) {
    this.createContent(
      display
        ? this.platform.SAFARI
        : !this.platform.SAFARI,
    );
  }

  createContent(show: boolean = true): void {
    this.container.clear();
    if (show) {
      this.container.createEmbeddedView(this.templateRef);
    }
  }

}
