import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filteredJoin',
  standalone: true,
})
export class FilteredJoinPipe implements PipeTransform {

  transform(value: string[], filter: string): string {
    if (!value) {
      return null;
    }

    return value
      .filter((item) => item !== filter)
      .join(', ');
  }

}
