import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { LIFE_CYCLE_FEATURE_KEY } from './life-cycle.selectors';

export const lifeCycleActions = createActionGroup({
  source: LIFE_CYCLE_FEATURE_KEY,
  events: {
    Initialize: emptyProps(),
    'Load error': props<{ error: Error }>(),
    'Reset state': emptyProps(),
    Redirect: props<{ url: string }>(),
  },
});
