import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import {
  FlattenedLicense,
  LicenseState,
  readAccessLicenseStates,
  supportAccessLicenseStates,
  writeAccessLicenseStates,
} from '@pixacare/pxc-ts-core';
import { selectClient, selectClients } from '../clients/clients.selectors';
import { selectClientCode } from '../router/router.selectors';
import { LicensesState } from './licenses.state';

export const LICENSES_FEATURE_KEY = 'Licenses';

export const selectLicensesState = createFeatureSelector<LicensesState>(
  LICENSES_FEATURE_KEY,
);

export const selectLicenses = createSelector(
  selectLicensesState,
  (state) => Object.values(state.licenses),
);

export const selectIsUserClientAdmin = (
  userId: number,
  clientCode: string,
): MemoizedSelector<LicensesState, boolean> => createSelector(
  selectLicensesState,
  selectClient(clientCode),
  (state, client) => !!Object.values(state.licenses)
    ?.filter((license) => license.userId === userId && license.clientId === client.id)
    ?.find((license) => license.state === LicenseState.ADMINISTRATOR),
);

export const selectReadAccessClientCodes = createSelector(
  selectLicensesState,
  selectClients,
  (state, clients) => Object.values(state.licenses)
    .filter((license) => readAccessLicenseStates.includes(license.state))
    .map((license) => Object.values(clients ?? {})
      .find((client) => client.id === license.clientId)?.code),
);

export const selectWriteAccessClientIds = createSelector(
  selectLicensesState,
  (state) => Object.values(state.licenses ?? {})
    .filter((license) => writeAccessLicenseStates.includes(license.state))
    .map((license) => license.clientId),
);

export const selectUserActiveLicense = createSelector(
  selectLicensesState,
  selectClientCode,
  selectClients,
  (state, cc, clients) =>
    Object.values(state.licenses)
      ?.find((license) => license.clientId === clients[cc]?.id),
);

export const selectUserActiveLicenseState = createSelector(
  selectUserActiveLicense,
  (license) => license?.state,
);

export const selectUserId = createSelector(
  selectUserActiveLicense,
  (license) => license?.userId,
);

export const selectHasLicenseSupportAccess = createSelector(
  selectUserActiveLicenseState,
  (licenseState) => supportAccessLicenseStates.includes(licenseState),
);

export const selectHasLicenseWriteAccess = createSelector(
  selectUserActiveLicenseState,
  (licenseState) => writeAccessLicenseStates.includes(licenseState),
);

export const selectFlattenedLicenses = createSelector(
  selectLicensesState,
  selectClients,
  (state, clients): FlattenedLicense[] => clients != null ? (Object.values(state.licenses) || []).map((license) => {
    const client = Object.values(clients).find((cl) => cl.id === license.clientId);
    return {
      license,
      client,
      clientDataConsumption: client?.clientDataConsumption,
    };
  }) : [],
);
