import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/services/navigation.service';

@Directive({
  selector: '[backButton]',
})
export class BackButtonDirective implements OnInit {

  @Input() defaultBackUrl: string = null;
  @Input() forceDefault = false;

  constructor(
    private readonly navigation: NavigationService,
    private readonly router: Router,
    private elementRef: ElementRef,
  ) { }

  @HostListener('click')
  onClick(): void {
    if (this.forceDefault) {
      this.router.navigate([this.defaultBackUrl], {
        queryParamsHandling: 'merge',
      });
      return;
    }

    this.navigation.back(this.defaultBackUrl);
  }

  ngOnInit(): void {
    if (!this.navigation.hasPreviousPage() && !this.defaultBackUrl) {
      this.elementRef.nativeElement.remove();
    }
  }

}
