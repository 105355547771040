import { AbstractControl, ValidatorFn } from '@angular/forms';

export const passwordValidator = (regex: string): ValidatorFn => (
  (control: AbstractControl): { [key: string]: unknown } | null => {
    const passwordRegex = new RegExp(regex);
    const forbidden = !passwordRegex.test(control.value);
    return forbidden ? { password: { value: control.value } } : null;
  }
);

export const phoneNumberValidator = (regex: string): ValidatorFn => (
  (control: AbstractControl): { [key: string]: unknown } | null => {
    const phoneNumberRegex = new RegExp(regex);
    const forbidden = control.value && !phoneNumberRegex.test(control.value);
    return forbidden ? { phoneNumber: { value: control.value } } : null;
  }
);
