import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from 'src/app/shared/models/enums/status.enum';

@Component({
  selector: 'pxc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {

  @Input() dark = false;
  @Input() fullscreen = false;
  @Input() useLogo = false;
  @Input() status: Status.LOADING | Status.ERROR = Status.LOADING;

  Status = Status;

}
