<div
  tuiCardLarge
  tuiSurface="outline"
  class="box-border h-full px-6 group-focus:bg-neutral-1"
>
  <div class="flex h-full items-start justify-between">
    <div class="flex items-start justify-start gap-2">
      <ng-content></ng-content>
      <ng-content select="[quick-actions]"></ng-content>
    </div>
    <ng-content select="[dropdown-menu]"></ng-content>
  </div>
</div>
