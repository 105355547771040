<div class="flex flex-col p-1.5">
  @for (action of actions; track action) {
    <button
      class="flex min-w-40 max-w-96 items-center gap-2 rounded-xl p-2 text-secondary"
      [ngClass]="
        action.status === Status.ERROR
          ? 'hover:bg-status-negative-pale hover:text-status-negative'
          : 'hover:bg-neutral-1 hover:text-primary'
      "
      (click)="action.execute(entity)"
    >
      <tui-icon
        data-testid="icon"
        [icon]="action.icon"
        class="h-5"
      ></tui-icon>
      <p class="mr-2 line-clamp-1 text-left text-current">
        {{ action.label }}
      </p>
    </button>
  }
</div>
