import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'pxc-pdf-previewer',
  templateUrl: './pdf-previewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfPreviewerComponent {

  @Input() source: SafeResourceUrl;

}
