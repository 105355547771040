import { JwtPayload, jwtDecode } from 'jwt-decode';
import { TokenPayload } from '../models/token-payload';

export const isTokenValid = (token: TokenPayload): boolean => token && token.valid === '1';

export const decodeToken = (token: string): TokenPayload => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64)
    .split('')
    .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

  return JSON.parse(jsonPayload);
};

export const isRtpTokenExpired = (rtpToken: string): boolean => {
  if (!rtpToken) {
    return true;
  }
  const decodedToken = jwtDecode<JwtPayload>(rtpToken);
  return decodedToken?.exp === undefined || Math.floor(Date.now() / 1000) >= decodedToken?.exp;
};
