import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { customMonthLabels } from 'src/app/shared/utils/custom-month-labels';

@Component({
  selector: 'pxc-date-preview',
  templateUrl: './date-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePreviewComponent implements OnChanges {

  @Input() date: Date = null;
  @Input() displayTime = true;

  year: string;
  month: string;
  day: string;
  time: string;

  ngOnChanges({ date }: SimpleChanges) {
    if (date.currentValue) {

      if (this.date instanceof Date === false) {
        this.date = new Date(this.date);
      }

      this.year = this.date.getFullYear().toString();
      this.month = customMonthLabels[this.date.getMonth()];
      this.day = this.date.getDate().toString().padStart(2, '0');
      if (this.displayTime) {
        this.time = this.date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      }
    }

  }

}
