import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { HandleDirection } from 'src/app/shared/models/enums/handle-direction.enum';

@Component({
  selector: 'pxc-handle',
  templateUrl: './handle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HandleComponent {

  @Input() handleState = true;
  @Input() direction = HandleDirection.VERTICAL;

  @Output() handleClicked: EventEmitter<void> = new EventEmitter();

  HandleDirection = HandleDirection;

  toggleHandleState(): void {
    this.handleState = !this.handleState;
    this.handleClicked.emit();
  }

}
