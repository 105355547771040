import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'pxc-carousel-navigation',
  templateUrl: './carousel-navigation.component.html',
  styles: [`
    ::ng-deep .carousel .t-item {
      padding: .2rem !important;
    }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselNavigationComponent {

  @Input() itemsCount = 3;
  @Input() max = this.itemsCount;
  @Input() hideControlsOnMobile = false;
  @Input() index = 0;

  @Output() indexChange = new EventEmitter<number>();

  previous(): void {
    this.indexChange.emit(Math.max(
      0,
      this.index - (this.index % this.itemsCount || this.itemsCount),
    ));
  }

  next(): void {
    this.indexChange.emit(Math.min(
      this.index + this.itemsCount,
      this.max - this.itemsCount,
    ));
  }

}
