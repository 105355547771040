@switch (state) {
  @case (UserState.ENABLED) {
    <tui-badge
      appearance="success"
      tuiStatus
    >
      Actif{{ count ? 's : ' + count : '' }}
    </tui-badge>
  }
  @case (UserState.INVITED) {
    <tui-badge
      appearance="neutral"
      tuiStatus
    >
      Invitation envoyée{{ count ? 's : ' + count : '' }}
    </tui-badge>
  }
  @case (UserState.DISABLED) {
    <tui-badge
      appearance="warning"
      tuiStatus
    >
      Désactivé{{ count ? 's : ' + count : '' }}
    </tui-badge>
  }
}
