import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-menu-actions',
  templateUrl: './menu-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuActionsComponent<T> {

  @Input() entity: T;
  @Input() actions: MenuAction<T>[];

  Status = Status;

}
