import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'pxc-create-button',
  templateUrl: './create-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateButtonComponent {

  @Input() icon = '@tui.plus';
  @Input() label = 'Ajouter';
  @Input() appearance = 'primary';
  @Input() disabled = false;

}
