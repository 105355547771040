import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiTable } from '@taiga-ui/addon-table';
import { TuiActiveZone, TuiLet } from '@taiga-ui/cdk';
import {
  TuiAlert,
  TuiButton,
  TuiDataList,
  TuiDropdown,
  TuiGroup,
  TuiHint,
  TuiIcon,
  TuiLink,
  TuiLoader,
  TuiScrollbar,
  TuiSurface,
  TuiTitle,
} from '@taiga-ui/core';
import {
  TuiAvatar,
  TuiBadge,
  TuiButtonLoading,
  TuiDataListWrapper,
  TuiElasticContainer,
  TuiFilterByInputPipe,
  TuiStringifyContentPipe,
  TuiSwitch,
  TuiTooltip,
} from '@taiga-ui/kit';
import { TuiCardLarge } from '@taiga-ui/layout';
import {
  TuiComboBoxModule,
  TuiMultiSelectModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { LottieComponent } from 'ngx-lottie';
import { ReversePipe } from 'src/app/modules/shared/pipes/reverse.pipe';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { JoinPipe } from 'src/app/shared/pipes/join.pipe';
import { OrderByPipe } from 'src/app/shared/pipes/order-by.pipe';
import { PluralizePipe } from 'src/app/shared/pipes/pluralize.pipe';
import { ActionButtonsComponent } from './action-buttons/action-buttons.component';
import { BackNavigationButtonComponent } from './back-navigation-button/back-navigation-button.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { CardDetailsComponent } from './card-details/card-details.component';
import { CarouselNavigationComponent } from './carousel-navigation/carousel-navigation.component';
import { ChoicePickerComponent } from './choice-picker/choice-picker.component';
import { CloseConfirmableDialogComponent } from './close-confirmable-dialog/close-confirmable-dialog.component';
import { CreateButtonComponent } from './create-button/create-button.component';
import { DatePreviewComponent } from './date-preview/date-preview.component';
import { BackButtonDirective } from './directives/back-button.directive';
import { ScrollTrackerDirective } from './directives/scroll-tracker.directive';
import { ShowIfClientEnabledDirective } from './directives/show-if-client-enabled.directive';
import { ShowIfSafariDirective } from './directives/show-if-safari.directive';
import { ShowIfStorageSpaceAvailableDirective } from './directives/show-if-storage-space-available.directive';
import { ShowIfUserAuthorizedDirective } from './directives/show-if-user-authorized.directive';
import { DropdownButtonComponent } from './dropdown-button/dropdown-button.component';
import { HandleComponent } from './handle/handle.component';
import { LastUpdateBadgeComponent } from './last-update-badge/last-update-badge.component';
import { LoaderComponent } from './loader/loader.component';
import { LottieLabelComponent } from './lottie-label/lottie-label.component';
import {
  MedicalSpecialtyInputComponent,
} from './medical-specialty-input/medical-specialty-input.component';
import { MenuActionsComponent } from './menu-actions/menu-actions.component';
import { PdfPreviewerComponent } from './pdf-previewer/pdf-previewer.component';
import { SaturatedStorageComponent } from './saturated-storage/saturated-storage.component';
import { ServerUnavailableComponent } from './server-unavailable/server-unavailable.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ToggleComponent } from './toggle/toggle.component';
import { UserAdminStateBadgeComponent } from './user-state-badge/user-admin-state-badge.component';
import { ZoomContainerComponent } from './zoom-container/zoom-container.component';

@NgModule({
  declarations: [
    LottieLabelComponent,
    ServerUnavailableComponent,
    MedicalSpecialtyInputComponent,
    ScrollTrackerDirective,
    ShowIfUserAuthorizedDirective,
    ShowIfClientEnabledDirective,
    ShowIfStorageSpaceAvailableDirective,
    DatePreviewComponent,
    OrderByPipe,
    ReversePipe,
    JoinPipe,
    PluralizePipe,
    CardDetailsComponent,
    LoaderComponent,
    MenuActionsComponent,
    ToggleComponent,
    ZoomContainerComponent,
    HandleComponent,
    CardButtonComponent,
    ChoicePickerComponent,
    PdfPreviewerComponent,
    ShowIfSafariDirective,
    CarouselNavigationComponent,
    SaturatedStorageComponent,
    BackButtonDirective,
    BackNavigationButtonComponent,
    ActionButtonsComponent,
    LastUpdateBadgeComponent,
    CloseConfirmableDialogComponent,
    CreateButtonComponent,
    DropdownButtonComponent,
  ],
  imports: [
    CommonModule,
    LottieComponent,
    FormsModule,
    ReactiveFormsModule,
    PlatformModule,
    TuiSwitch,
    TuiAlert,
    TuiIcon,
    TuiBadge,
    TuiLet,
    TuiButton,
    TuiButtonLoading,
    ...TuiDropdown,
    TuiGroup,
    ...TuiDataList,
    TuiScrollbar,
    TuiLoader,
    TuiElasticContainer,
    ...TuiDataListWrapper,
    TuiMultiSelectModule,
    TuiTextfieldControllerModule,
    TuiComboBoxModule,
    TuiFilterByInputPipe,
    TuiStringifyContentPipe,
    ...TuiTable,
    ...TuiHint,
    TuiAvatar,
    UserCardComponent,
    TuiActiveZone,
    UserAdminStateBadgeComponent,
    SpinnerComponent,
    TuiLink,
    TuiTooltip,
    TuiSurface,
    TuiCardLarge,
    TuiTitle,
  ],
  exports: [
    MedicalSpecialtyInputComponent,
    ScrollTrackerDirective,
    ShowIfUserAuthorizedDirective,
    ShowIfClientEnabledDirective,
    ShowIfStorageSpaceAvailableDirective,
    DatePreviewComponent,
    OrderByPipe,
    ReversePipe,
    PluralizePipe,
    CardDetailsComponent,
    LoaderComponent,
    MenuActionsComponent,
    ToggleComponent,
    ZoomContainerComponent,
    HandleComponent,
    CardButtonComponent,
    ChoicePickerComponent,
    PdfPreviewerComponent,
    ShowIfSafariDirective,
    CarouselNavigationComponent,
    SaturatedStorageComponent,
    BackButtonDirective,
    LottieLabelComponent,
    BackNavigationButtonComponent,
    ActionButtonsComponent,
    LastUpdateBadgeComponent,
    CloseConfirmableDialogComponent,
    CreateButtonComponent,
    UserCardComponent,
    DropdownButtonComponent,
    UserAdminStateBadgeComponent,
  ],
  providers: [
    TitleCasePipe,
    UpperCasePipe,
  ],
})
export class SharedModule { }
