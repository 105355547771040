import { Injectable } from '@angular/core';
import { AvatarStyle, getAvatarColors } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class AvatarStyleService {

  getAvatarColors = getAvatarColors;

  system(): AvatarStyle {
    return {
      backgroundColor: 'var(--tui-background-neutral-2)',
      borderColor: 'var(--tui-background-neutral-2)',
      textColor: 'var(--tui-background-accent-1)',
    };
  }

  alert(): AvatarStyle {
    return {
      backgroundColor: 'var(--tui-status-negative-pale)',
      borderColor: 'var(--tui-status-negative)',
      textColor: 'var(--tui-status-negative)',
    };
  }

}
