export const environment = {
  commonClientCode: 'common',
  production: false,
  apiBaseUrl: 'https://api.ploon.pixacare.com',
  appInsights: {
    instrumentationKey: '48616bc2-1351-4811-b737-4ff5288f37db',
  },
  cdn: {
    clientBaseUrl: 'https://cdn.pixacare.com/clients/',
  },
  firebaseCloudFunctionsBaseUrl: 'https://europe-west1-pixacare-5bc61.cloudfunctions.net',
  firebase: {
    apiKey: 'AIzaSyAaoMZDcL_vkQFfyLEpKOzKBXzaHWmMHEM',
    authDomain: 'pixacare-5bc61.firebaseapp.com',
    databaseURL: 'https://pixacare-5bc61.firebaseio.com',
    projectId: 'pixacare-5bc61',
    storageBucket: 'pixacare-5bc61.appspot.com',
    messagingSenderId: '97913442562',
    appId: '1:97913442562:web:0ee5dfd6bc5b70378af21b',
    measurementId: 'G-1JWWX2HM43',
  },
  omnidoc: {
    newTeUri: 'https://playground.omnidoc.fr/interop/drafts/new#',
  },
  hubspot: {
    formUrl: 'https://www.pixacare.com/formulaire-support-client',
  },
};
