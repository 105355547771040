import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectClients } from '../clients/clients.selectors';
import { selectDepartments } from '../departments/departments.selectors';
import { selectFlattenedLabels } from '../labels/labels.selectors';
import { LifeCycleState } from './life-cycle.state';

export const LIFE_CYCLE_FEATURE_KEY = 'LifeCycle';

export const selectLifeCycleState = createFeatureSelector<LifeCycleState>(
  LIFE_CYCLE_FEATURE_KEY,
);

export const selectIsStoreInitialized = createSelector(
  selectClients,
  selectFlattenedLabels,
  selectDepartments,
  (clients, labels, departments) => {
    if (!clients || !labels || !departments) {
      return false;
    }
    return (
      // Departments check
      Object.values(clients)?.every(({ code }) => !!departments[code] )
    );
  },
);

export const selectError = createSelector(
  selectLifeCycleState,
  (state) => state.error,
);
