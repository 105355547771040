import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ExportsState } from './exports.state';

export const EXPORTS_FEATURE_KEY = 'Exports';

export const selectExportsState = createFeatureSelector<ExportsState>(
  EXPORTS_FEATURE_KEY,
);

export const selectDataExports = createSelector(
  selectExportsState,
  (state) => state.exports,
);
