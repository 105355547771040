import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Client, ClientDataConsumption } from '@pixacare/pxc-ts-core';
import { ClientsState } from './clients.state';

export const CLIENTS_FEATURE_KEY = 'Clients';

export const selectClientsState = createFeatureSelector<ClientsState>(
  CLIENTS_FEATURE_KEY,
);

export const selectClients = createSelector(
  selectClientsState,
  (state) => state.clients,
);

export const selectClient = (
  clientCode: string,
): MemoizedSelector<ClientsState, Client> => createSelector(
  selectClientsState,
  (state) => state.clients[clientCode],
);

export const selectIsGamEnabled = (
  clientCode: string,
): MemoizedSelector<ClientsState, boolean> => createSelector(
  selectClientsState,
  (state) => state.clients[clientCode].isGamEnabled,
);

export const selectIsEhrEnabled = (
  clientCode: string,
): MemoizedSelector<ClientsState, boolean> => createSelector(
  selectClientsState,
  (state) => state.clients[clientCode].isEhrEnabled,
);

export const selectClientDataConsumptionInGb = (
  clientCode: string,
): MemoizedSelector<ClientsState, ClientDataConsumption> => createSelector(
  selectClientsState,
  (state) => {
    if (!clientCode) {
      return {
        total: null,
        used: null,
      };
    }
    const dataConsumption = state.clients[clientCode]?.clientDataConsumption;
    return {
      ...dataConsumption,
      total: +(dataConsumption.total / (1024 ** 3)).toFixed(2),
      used: +(dataConsumption.used / (1024 ** 3)).toFixed(2),
    };
  },
);
