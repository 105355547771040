<div class="flex flex-col gap-4">
  @if (title) {
    <h3
      tuiTitle="m"
      data-testid="title"
    >
      {{ title }}
    </h3>
  }
  <div class="mx-1 grid gap-3 md:grid-cols-2">
    <ng-content></ng-content>
  </div>
</div>
